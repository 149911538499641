import styled from 'styled-components'

export const CopyrightContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 26px 0;
  }
`

export const LogoFooter = styled.img`
  margin-bottom: 48px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
    margin-right: 30px;
  }
`

export const BussinessInfo = styled.span`
  text-align: center;
  font-size: 14px;

  @media screen and (min-width: 1024px) {
    text-align: left;
  }
`

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Address = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  text-align: center;
`
