import React, { useState } from 'react'
import renderHTML from 'react-render-html'

import * as S from './styled'

import PlusIcon from '../../../images/plus.svg'
import MinusIcon from '../../../images/minus.svg'

const Accordion = () => {
  const [faq, setFAQ] = useState([
    {
      question: 'Preciso ter CNPJ para emitir boletos com a Cora?',
      answer:
        'Sim, é necessário que sua empresa tenha CNPJ para ter o cadastro aprovado na Cora. Nosso app é destinado para autônomos, profissionais liberais e donos de pequenos e médios negócios, com empresas constituídas',
      open: false,
    },
    {
      question: 'Quanto tempo leva para eu começar a emitir boletos pela Cora?',
      answer:
        'Fazer seu cadastro é muito simples. Após concluir,  é necessário aguardar o prazo de 5 dias úteis para análise. Depois de aprovado o cadastro, o aplicativo é liberado imediatamente e você já pode emitir seus boletos de cobrança.',
      open: false,
    },
    {
      question: 'Posso emitir boletos para pessoa física e pessoa jurídica?',
      answer:
        'Sim! Você pode emitir boletos de cobrança, via app da Cora, tanto para pessoa jurídica quanto para pessoa física.',
      open: false,
    },
    {
      question:
        'Quanto tempo leva para os valores aparecerem em minha conta Cora?',
      answer:
        'Os valores pagos pelos boletos de cobrança gerados pela Cora são creditados na conta em até 3 dias úteis, após o pagamento.',
      open: false,
    },
    {
      question: 'Vou pagar algum tipo de taxa para abrir a conta na Cora?',
      answer:
        'Não. O aplicativo da Cora é uma solução gratuita. Nenhum valor será cobrado para abertura ou manutenção da conta. Você também tem gratuidade em transferências Pix e TED, além de diversos serviços gratuitos para sua empresa, como a emissão de boletos (com cobrança de R$1,90 por boleto, após a 100ª cobrança compensada no mês).',
      open: false,
    },
    {
      question: 'Posso integrar a Cora com outros sistemas?',
      answer:
        'Sim. É possível integrar a Cora com ERPs e sistemas contábeis elegíveis ao nosso programa de parcerias. Neste caso a integração é realizada por meio de nossa API e é cobrado um valor de acordo com o volume de cobranças. Para saber mais, <a href="https://meajuda.cora.com.br/hc/pt-br/articles/9885385328275-Como-solicitar-a-integração-de-Parceria-Cora-e-ter-as-minhas-credenciais-" target="_blank" rel=”nofollow”>clique aqui.</a>',
      open: false,
    },
    {
      question:
        'É cobrado algum tipo de taxa para receber boletos gerados pelo app?',
      answer:
        'Atualmente, oferecemos aos nossos clientes a vantagem de não cobrar taxas tanto para a emissão quanto para o cancelamento de boletos através do aplicativo e da Cora Web. No entanto, é importante destacar que, apesar da gratuidade na emissão e cancelamento, existem limites para a compensação dos boletos. Para clientes <strong>não MEI’s</strong> a gratuidade é até 100 boletos compensados por mês. Ou seja, a partir do 101º boleto que tenha sido pago pelo seu cliente, haverá uma cobrança de <strong>R$ 1,90</strong> por boleto excedente, que será debitada diretamente da sua conta Cora.<br /><br />Para clientes <strong>MEI’s</strong>, a Cora oferece uma política um pouco diferente. O limite de gratuidade para compensação é de 10 boletos no mês. Sendo assim, a partir do 11º boleto compensado, será cobrada a mesma taxa de <strong>R$ 1,90</strong> por boleto excedente.',
      open: false,
    },
    {
      question: 'Preciso pagar alguma taxa para cancelar boletos?',
      answer:
        'Não. Nenhum tipo de custo ou taxa é cobrado para cancelar boletos emitidos pelo aplicativo da Cora.',
      open: false,
    },
  ])

  const toogle = index => {
    setFAQ(
      faq.map((item, i) => {
        if (i === index) {
          item.open = !item.open
        }

        return item
      })
    )
  }

  return (
    <>
      {faq.map((item, i) => (
        <S.AccordionWrapper key={item.question}>
          <S.AccordionHeader onClick={() => toogle(i)}>
            <S.AccordionQuestion>{item.question}</S.AccordionQuestion>
            <S.AccordionIcon
              src={item.open ? MinusIcon : PlusIcon}
              alt={item.open ? 'Abrir resposta' : 'Fechar Resposta'}
            />
          </S.AccordionHeader>

          <S.AccordionContent open={item.open}>
            <S.AccordionAnswer>{renderHTML(item.answer)}</S.AccordionAnswer>
          </S.AccordionContent>
        </S.AccordionWrapper>
      ))}
    </>
  )
}

export default Accordion
