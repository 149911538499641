import React, { FC } from 'react'

import Copyright from '../../Copyright/old'
import Social from '../../Social/old'
import IconGooglePlay from '../../IconGooglePlay/old'
import IconAppStore from '../../IconAppStore/old'

import * as S from './styled'

interface FooterProps {
  storeLink: string
}

const Footer: FC<FooterProps> = ({ storeLink }: FooterProps) => (
  <S.FooterWrapper>
    <div className="container">
      <S.FooterContent>
        <S.FooterRow>
          <S.ListWrapper>
            <S.ListTitle>Cora</S.ListTitle>
            <S.List>
              <li>
                <S.Link
                  href="https://www.cora.com.br/blog/sobre-a-cora/"
                  target="_blank"
                >
                  Sobre a Cora
                </S.Link>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.Link
                  href="https://pj.cora.com.br/sala-de-imprensa/"
                  target="_blank"
                >
                  Imprensa
                </S.Link>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.Link
                  href="https://www.cora.com.br/vagas-cora"
                  target="_blank"
                >
                  Carreiras
                </S.Link>
              </li>
            </S.List>
            <S.List>
              <li>
                <S.Link
                  href="https://comunidade.cora.com.br/parceiros/"
                  target="_blank"
                >
                  Contabilidade
                </S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
          <S.ListWrapper>
            <S.ListTitle>Produtos</S.ListTitle>
            <S.List>
              <li>
                <S.Link href="/conta-pj">Conta PJ</S.Link>
              </li>
              <li>
                <S.Link href="/cartao-pj">Cartão PJ</S.Link>
              </li>
              <li>
                <S.Link href="/conta-pj/gestao-de-cobrancas">Cobranças</S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
          <S.ListWrapper>
            <S.ListTitle>Recursos</S.ListTitle>
            <S.List>
              <li>
                <S.Link href="https://www.cora.com.br/blog/">Blog</S.Link>
              </li>
              <li>
                <S.Link href="https://www.cora.com.br/blog/ferramentas-cora/">
                  Ferramentas gratuitas
                </S.Link>
              </li>
              <li>
                <S.Link href="https://www.cora.com.br/blog/educacao-financeira/">
                  Educação Financeira
                </S.Link>
              </li>
              <li>
                <S.Link href="https://developers.cora.com.br/">
                  Integrações via API
                </S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
          <S.ListWrapper>
            <S.ListTitle>Suporte</S.ListTitle>
            <S.List>
              <li>
                <S.Link href="http://meajuda.cora.com.br/" target="_blank">
                  Central de Ajuda
                </S.Link>
              </li>

              <li>
                <S.Link
                  href="https://pj.cora.com.br/seguranca/"
                  target="_blank"
                >
                  Segurança
                </S.Link>
              </li>
            </S.List>
            <br />
            <S.ListTitle>Contato</S.ListTitle>
            <S.List>
              <li>
                Canais 100% digitais:
                <br />
                <S.Link href="tel:1150395715">(11) 5039-5715</S.Link>
              </li>
              <li>
                <S.Link href="tel:08005912350">0800 591 2350</S.Link>
                <br />
              </li>
              <li>
                <S.Link href="https://mobile.cora.com.br/home/chat">
                  Chat no aplicativo - <span>Atendimento 24h</span>
                </S.Link>
              </li>
              <li>
                <S.Link href="mailto:meajuda@cora.com.br">
                  meajuda@cora.com.br
                </S.Link>
              </li>
            </S.List>
          </S.ListWrapper>
        </S.FooterRow>
        <S.FooterRow>
          <S.ListWrapper className="store-list">
            <S.ListTitle>Baixe o app</S.ListTitle>
            <S.LinkStoresContainer>
              <IconGooglePlay className="store-icon" small link={storeLink} />
              <IconAppStore className="store-icon" small link={storeLink} />
            </S.LinkStoresContainer>
          </S.ListWrapper>
        </S.FooterRow>
      </S.FooterContent>
    </div>
    <S.FooterCopyright>
      <div className="container">
        <S.FooterCopyrightContent>
          <Copyright />

          <Social />
        </S.FooterCopyrightContent>
      </div>
    </S.FooterCopyright>
  </S.FooterWrapper>
)

export default Footer
