import React, { FC } from 'react'

import * as S from './styled'

import Instagram from '../../../images/instagram.svg'
import LinkedIn from '../../../images/linkedin.svg'
import Facebook from '../../../images/facebook.svg'

const Social: FC = () => (
  <S.SocialWrapper>
    <S.SocialItem>
      <S.Link
        href="https://www.instagram.com/cora.bancopj/"
        title="Instagram da Cora"
      >
        <img src={Instagram} alt="Instagram da Cora" />
      </S.Link>
    </S.SocialItem>
    <S.SocialItem>
      <S.Link
        href="https://www.linkedin.com/company/cora-bancopj/"
        title="LinkedIn da Cora"
      >
        <img src={LinkedIn} alt="LinkedIn da Cora" />
      </S.Link>
    </S.SocialItem>
    <S.SocialItem>
      <S.Link
        href="https://www.facebook.com/cora.bancopj/"
        title="Facebook da Cora"
      >
        <img src={Facebook} alt="Facebook da Cora" />
      </S.Link>
    </S.SocialItem>
    <S.SocialItem>
      <S.Link href="https://twitter.com/Cora_BancoPJ/" title="Twitter da Cora">
        <svg
          id="Layer_1"
          x={0}
          y={0}
          viewBox="0 0 32 32"
          enableBackground="new 0 0 32 32"
          xmlSpace="preserve"
        >
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 4h12c.8 0 1.6.2 2.3.5.7.3 1.4.7 1.9 1.3.6.6 1 1.2 1.3 1.9.3.7.5 1.5.5 2.3v12c0 .8-.2 1.6-.5 2.3-.3.7-.7 1.4-1.3 1.9-.6.6-1.2 1-1.9 1.3-.7.3-1.5.5-2.3.5H10c-.8 0-1.6-.2-2.3-.5-.7-.3-1.4-.7-1.9-1.3s-1-1.2-1.3-1.9c-.3-.7-.5-1.5-.5-2.3V10c0-.8.2-1.6.5-2.3.3-.7.7-1.4 1.3-1.9s1.2-1 1.9-1.3C8.4 4.2 9.2 4 10 4z"
          />
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.5 12.8c-.1-1.1-.9-1.9-1.9-2.1h-.8c-.3.1-.6.2-.8.3-.2.2-.5.4-.6.6 0 0 0 0 0 0l-6.8 9.7h5.5c3 0 5.5-2.4 5.5-5.5 0 0 0 0 0 0v-.6l1.9-1.5-2-.9z"
          />
          <circle cx={18.2} cy={13.5} r={0.6} fill="#202020" />
        </svg>
      </S.Link>
    </S.SocialItem>
    <S.SocialItem>
      <S.Link
        href="https://www.youtube.com/@Cora.BancoPJ/"
        title="Youtube da Cora"
      >
        <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 32 32"
          enableBackground="new 0 0 32 32"
          xmlSpace="preserve"
        >
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10,4h12c0.8,0,1.6,0.2,2.3,0.5c0.7,0.3,1.4,0.7,1.9,1.3c0.6,0.6,1,1.2,1.3,1.9C27.8,8.4,28,9.2,28,10v12  c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.7-0.7,1.4-1.3,1.9c-0.6,0.6-1.2,1-1.9,1.3C23.6,27.8,22.8,28,22,28H10c-0.8,0-1.6-0.2-2.3-0.5  c-0.7-0.3-1.4-0.7-1.9-1.3s-1-1.2-1.3-1.9C4.2,23.6,4,22.8,4,22V10c0-0.8,0.2-1.6,0.5-2.3C4.8,7,5.2,6.3,5.8,5.8s1.2-1,1.9-1.3  C8.4,4.2,9.2,4,10,4z"
          ></path>
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.3,15.3l-8-4.8c-0.3-0.1-0.6-0.1-0.8,0c-0.2,0.1-0.4,0.4-0.4,0.7v9.6c0,0.3,0.1,0.6,0.4,0.7  c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1l8-4.8l0,0c0.2-0.2,0.4-0.4,0.4-0.7C21.7,15.7,21.5,15.4,21.3,15.3z"
          ></path>
        </svg>
      </S.Link>
    </S.SocialItem>
  </S.SocialWrapper>
)

export default Social
