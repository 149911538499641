import React, { FC } from 'react'

import * as S from './styled'
import Logo from '../../../images/logo-footer.svg'

const Copyright: FC = () => (
  <S.CopyrightContent>
    <S.LogoFooter src={Logo} alt="Logo da Cora" />
    <S.AddressWrapper>
      <S.BussinessInfo>
        Cora Sociedade de Crédito, Financiamento e Investimento S.A. -
        34.052.649/0001-78
      </S.BussinessInfo>
      <S.Address>
        Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação - São Paulo/SP
      </S.Address>
    </S.AddressWrapper>
  </S.CopyrightContent>
)

export default Copyright
